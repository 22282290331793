<template>
  <section class="almacen-materiales">
        <navbar-admin>
            <div class="row">
                <div class="col my-auto" v-if="!id">
                    <el-checkbox v-model="createAnotherMaterial">Crear otro</el-checkbox>
                </div>
                <div class="col pl-0">
                    <button class="btn btn-cerrar f-12" @click="close">Cerrar</button>
                </div>
                <div class="col pl-0">
                    <button class="btn btn-crear f-12" @click="beforeSaveMaterial">{{id ? 'Guardar' : 'Crear'}}</button>
                </div>
            </div>
        </navbar-admin>
        <div class="row pl-4">
            <div class="col-12 col-lg-7">
                <titulo-divisor titulo="Datos básicos" />
            </div>
        </div>
        <ValidationObserver ref="validator">
            <div class="row pl-4">
                <div class="col-12 col-lg-7">
                        <p class="mb-4">
                            <required text="Campos obligatorios"/>
                        </p>
                        <div class="row">
                            <div class="col-12 col-lg-6 mb-3">
                                <ValidationProvider v-slot="{ errors }" name="nombre" rules="required">
                                    <p class="input-label-top">Nombre <required/></p>
                                    <el-input v-model="model.nombre" size="small" placeholder="" class="w-100" />
                                    <vee-error :text="errors[0]"/>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-lg-6 mb-3">
                                <p class="input-label-top">Referencia </p>
                                <el-input v-model="model.referencia" size="small" placeholder="" class="w-100" />
                            </div>
                            <div class="col-12 col-lg-6 mb-3">
                                <ValidationProvider v-slot="{ errors }" name="tipo de material" rules="required">
                                    <p class="input-label-top">Tipo de material <required/></p>
                                    <el-select v-model="model.id_tipo_material" placeholder="Seleccionar" size="small" class="w-100">
                                        <el-option v-for="item in select_material_types" :key="item.id" :label="item.tipo_material" :value="item.id" />
                                    </el-select>
                                    <vee-error :text="errors[0]"/>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-lg-6 mb-3">
                                <ValidationProvider v-slot="{ errors }" name="unidad de medida" rules="required">
                                    <p class="input-label-top">Unidad de medida <required/></p>
                                    <el-select v-model="model.id_unidad_medida" placeholder="Seleccionar" size="small" class="w-100">
                                        <el-option v-for="item in select_measure_units" :key="item.id" :label="`${item.unidad_medida} (${item.sigla})`" :value="item.id" />
                                    </el-select>
                                    <vee-error :text="errors[0]"/>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-lg-6 mb-3">
                                <p class="input-label-top">Espesor</p>
                                <div class="d-middle">
                                        <el-input v-model="item" size="small" placeholder="" class="w-100" />
                                        <div class="icon-option">
                                            <i class="icon-plus-circle cr-pointer f-30 pl-2" @click="Guardar"/>
                                        </div>
                                </div>
                                <vee-error :text="espesorError"/>
                                <el-tag v-for="tag in model.espesor" :key="tag.id_almacen_materiales_espesor" closable type="info" @close="handleClose(tag)"> {{tag.nombre}} </el-tag>
                            </div>
                            <div class="col-12 col-lg-6 mb-3">
                                <ValidationProvider v-slot="{ errors }" name="valor teorico" rules="required|min:1">
                                    <p class="input-label-top">Valor teórico <required/></p>
                                    <CustomCurrencyInput 
                                        v-model="model.valor"
                                        :options="{ 
                                            currency: currencyFormat, 
                                            distractionFree: {
                                                hideNegligibleDecimalDigits: true,
                                                hideCurrencySymbol: true,
                                                hideGroupingSymbol: true
                                            }
                                        }"
                                    />
                                    <vee-error :text="errors[0]"/>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-lg-6 mb-3">
                                <ValidationProvider v-slot="{ errors }" name="moneda" rules="required">
                                    <p class="input-label-top">Seleccionar moneda <required/></p>
                                    <el-select v-model="model.id_moneda" placeholder="Seleccionar" size="small" class="w-100">
                                        <el-option v-for="item in select_monedas" :key="item.id" :label="item.nombre" :value="item.id" />
                                    </el-select>
                                    <vee-error :text="errors[0]"/>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-lg-6 mb-3">
                                <p class="input-label-top">Proveedor favorito</p>
                                <el-select v-model="model.id_proveedor_favorito" placeholder="Seleccionar" size="small" class="w-100">
                                    <el-option v-for="item in select_providers" :key="item.id" :label="item.proveedor" :value="item.id" />
                                </el-select>
                            </div>
                            <div class="col-12 col-lg-12 mb-3">
                                <p class="input-label-top">Descripción </p>
                                <el-input v-model="model.descripcion" type="textarea" class="w-100" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <titulo-divisor titulo="Datos adicionales" />
                            </div>
                        </div>
                        <template v-for="(aditional, index) in aditional_data">
                            <div class="row mb-3 pl-3">
                                <div class="col-12 col-lg-4 my-auto">
                                    <p class="f-14 f-500">{{aditional.pregunta}}</p>
                                </div>
                                <div class="col-12 col-lg-5">
                                    <template v-if="[1,4].includes(aditional.tipo)">
                                        <DynamicInput
                                        :ref="`dynamic-${aditional.id}`"
                                        :type="inputTypes[aditional.tipo] || 'text'"
                                        prop="valor"
                                        :model="datos_adicionales.find(el => el.id_configuracion_datos_adicionales == aditional.id)"
                                        @input="addAditional({$event, id: aditional.id, prop: 'valor'})"
                                        />
                                    </template>
                                    <template v-else-if="aditional.tipo == 2">
                                        <Selecter
                                        :ref="`dynamic-${aditional.id}`"
                                        :data="aditional_data_types.filter(el => el.id_configuracion_datos_adicionales == aditional.id)"
                                        :model="datos_adicionales.find(el => el.id_configuracion_datos_adicionales == aditional.id)"
                                        @selected="addAditional({$event, id: aditional.id, prop: 'id_configuracion_datos_adicionales_tipos'})"
                                        />
                                    </template>
                                    <template v-else-if="aditional.tipo == 3" >
                                        <Radios
                                        :ref="`dynamic-${aditional.id}`"
                                        :data="aditional_data_types.filter(el => el.id_configuracion_datos_adicionales == aditional.id)"
                                        :model="datos_adicionales.find(el => el.id_configuracion_datos_adicionales == aditional.id)"
                                        @selected="addAditional({$event, id: aditional.id, prop: 'id_configuracion_datos_adicionales_tipos'})"
                                        />
                                    </template>
                                </div>
                            </div>
                        </template>
                </div>
                <div class="col-4 mt-4">
                        <p class="f-12 pl-2 mt-3">Imagen del material </p>
                        <div style="width:161px">
                            <clean-cropper 
                            ref="cleanCropper"
                            v-model="image" 
                            :image="model.imagen"
                            />                        
                            <el-progress 
                            class="width:161px" 
                            :text-inside="true" 
                            :stroke-width="5" 
                            :percentage="percentage"
                            />
                        </div>
                </div>
            </div>
        </ValidationObserver>
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    data(){
        return{
            createAnotherMaterial: false,
            item: '',
            inputTypes: {
                1: 'text',
                4: 'number'
            },
            aditional: [],
            model:{
                id: null,
                nombre: '',
                referencia: '',
                id_tipo_material: null,
                id_unidad_medida: null,
                valor: null,
                id_proveedor_favorito: null,
                id_moneda: null,
                descripcion: '',
                imagen: null,
                espesor: [],
                datos_adicionales: []
            },
            espesorError: '',
            image: null,
        }
    },
    async created(){
        this.getBreadcumbs([
            'almacen.main',  
            'almacen.materiales', 
            this.id ? 'almacen.materiales.editar' : 'almacen.materiales.crear'
        ]);

        await Promise.all([
            this.getAdditionalDatatypes(),
            this.getAdditionalData(),
        ])

        if(this.select_material_types.length === 0 && this.select_measure_units.length === 0 && this.select_providers.length === 0) {
            this.getSelects([
                'Action_get_select_material_types', 
                'Action_get_select_measure_units',
                'Action_get_select_providers'
            ])
        }
        this.getSelects(['Action_get_select_monedas'])
        this.material = {}
        
        if(this.id){
            await this.getMaterial(this.id)
            this.spliceBreadcumbs([
                {  
                    position: 2,
                    breadcumb: { 
                        name: this.material.nombre, 
                        route: 'almacen.materiales.ver.datos', 
                        params: {id: this.material.id}
                    }
                }
            ]);
            this.loadData()
        }
    },
    computed:{
        ...mapGetters({
            aditional_data: 'almacen/materiales/aditional_data',
            aditional_data_types: 'almacen/materiales/aditional_data_types',
            select_material_types: 'selects/selects/select_material_types',
            select_measure_units: 'selects/selects/select_measure_units',
            select_providers: 'selects/selects/select_providers',
            select_monedas: 'selects/selects/select_monedas',
            percentage: 'files/files/percentage',
            key: 'files/files/key'
        }),
        currencyFormat() {
            return this.select_monedas.find(el => el.id == this.model.id_moneda)?.sigla ?? 'USD'
        },
        material: {
            set(val){
                this.$store.commit('almacen/materiales/set_material', val);
            },  
            get(){
                return this.$store.getters['almacen/materiales/material'];
            }
        },
        id(){
            return this.$route.params.id;
        },
        datos_adicionales(){
            // si no se esta editando retorna vacio
            if(!this.id || !this.material?.datos_adicionales) return [];
            // mapear datos adicionales 
            const aditional_mapped = this.material.datos_adicionales.map(({id_configuracion_datos_adicionales, id_select, valor}) => {
                const obj = id_select 
                    ? { id_configuracion_datos_adicionales_tipos: id_select}
                    : { valor }
                    
                return { id_configuracion_datos_adicionales, ...obj }
            }) 
            // retornar datos adicionales que se pasaran por prop a los componentes Selecter y Radios 
            return _.sortBy(aditional_mapped, 'id_configuracion_datos_adicionales')
        }
    },
    watch: {
        item(val){
            if(val != ''){
                this.espesorError = ''
            }
        }
    },
    methods:{
        ...mapActions({
            getMaterial: 'almacen/materiales/Action_get_material',
            createMaterial: 'almacen/materiales/Action_create_material',
            updateMaterial: 'almacen/materiales/Action_update_material',
            getAdditionalData: 'almacen/materiales/Action_get_additional_data',
            getAdditionalDatatypes: 'almacen/materiales/Action_get_additional_data_types',
            getBreadcumbs: 'navigation/breadcumbs/getBreadcumbs',
            spliceBreadcumbs: 'navigation/breadcumbs/spliceBreadcumbs',
            uploadFile: 'files/files/Action_save_file_to_aws',
            getSelects: 'selects/selects/sync'
        }),
        loadData(){
            this.model = {
                id: this.material.id,
                nombre: this.material.nombre, 
                referencia: this.material.referencia, 
                id_tipo_material:this.material.id_tipo_material,
                id_unidad_medida: this.material.id_unidad_medida,
                descripcion: this.material.descripcion,
                imagen: this.material.imagen,
                espesor: this.material.espesor,
                id_moneda: this.material.id_moneda,
                valor: parseFloat(this.material.valor),
                id_proveedor_favorito: this.material.id_proveedor_favorito,
            };
        },
        addAditional({$event, id, prop}){
            const element = this.datos_adicionales.find(el => el.id_configuracion_datos_adicionales == id);
            
            if(element) return element[prop] = $event;

            this.datos_adicionales.push({
                id_configuracion_datos_adicionales: id,
                [prop]: $event
            });
        },
        async beforeSaveMaterial(){
            let valid = await this.$refs.validator.validate();
            if(!valid) return;
            // get and uploading img to aws
            const base64Image = this.image

            if(base64Image) {
                await this.uploadFile({ 
                    file: await this.base64ToFile(base64Image),
                    path: '/almacen/materiales' 
                });
            }
            
            const payload = {
                ...this.model,
                datos_adicionales: this.datos_adicionales,
                imagen: base64Image ? this.key : this.material.imagen
            }

            const id = this.id
                ? await this.updateMaterial(payload)
                : await this.createMaterial(payload)

            const actual_id = id ?? this.id

            // redirect to material page
            if(this.createAnotherMaterial) return this.resetForm();
            if(actual_id) this.$router.push({ name: 'almacen.materiales.ver', params: { id: actual_id }});
        },
        close(){
            this.$router.push({ name: 'almacen.main' });
        },
        Guardar(){
            if(this.item == '') return this.espesorError = 'Este campo es requerido';
            this.model.espesor.push({id_almacen_materiales_espesor:null,nombre:this.item});
            this.item = '';
        },
        handleClose(val){
            this.model.espesor.splice(this.model.espesor.indexOf(val), 1);
        },
        resetForm(){
            this.model = {
                id: null,
                nombre: '',
                referencia: '',
                id_tipo_material: null,
                id_unidad_medida: null,
                valor: null,
                id_moneda: null,
                id_proveedor_favorito: null,
                descripcion: '',
                imagen: null,
                espesor: [],
                datos_adicionales: []
            }
            this.$refs.cleanCropper.reset();
            this.$refs.validator.reset();
            this.aditional_data.forEach(element =>  this.$refs?.[`dynamic-${element.id}`]?.[0]?.clear());
        }
    }

}
</script>

<style lang="scss" scoped>
.create-material-cropper{
    background-color:#F5F5F5;
    border-radius:6px;
    width: 161px; 
    height: 161px;
}

</style>